import React from "react"
import { Link as LinkS } from "react-scroll"
import { FaTimes } from "react-icons/fa"
import styled from "styled-components"

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="about">About</SidebarLink>
          <SidebarLink to="products">Products</SidebarLink>
          <SidebarLink to="devs">For Developers</SidebarLink>
          <SidebarLink to="contact">Contact Us</SidebarLink>
        </SidebarMenu>
        <SidebarBtnWrap>
          <SidebarRoute to="contact"> Join Us</SidebarRoute>
        </SidebarBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar

const SidebarContainer = styled.aside`
position: fixed;
z-index: 999;
width:100%;
height:100%;
background:#333333;
display: grid;
align-items: center;
top:0;
left: 0;
transition; 0.3s ease-in-out;
opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
top:${({ isOpen }) => (isOpen ? "0" : "-100%")};
`

const CloseIcon = styled(FaTimes)`
  color: #c79908;
`

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

const SidebarWrapper = styled.div`
  color: #fff;
`

const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-row: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`

const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #c79908;
    transition: 0.2s ease-in-out;
  }
`

const SidebarBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`

const SidebarRoute = styled(LinkS)`
  border-radius: 50px;
  background: #c79908;
  white-space: nowrap;
  padding: 16px 64px;
  color: #333333;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #000;
    color: #c79908;
  }
`

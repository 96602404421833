import { Link as LinkS } from "gatsby"

import React from "react"
import styled from "styled-components"

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterLinksWrapper>
          <FooterDesc>
            <h1>JOBILLS</h1>
            <p>
              Striving to improve human life through technological inclusion.
            </p>
          </FooterDesc>
          <FooterLinkItems>
            <FooterLinkTitle>Contact Us</FooterLinkTitle>

            <a
              style={{
                textDecoration: "none",
                marginBottom: "0.5rem",
                fontSize: "14px",
                color: "#c79908",

                // &:hover {
                //   color: '#800000',
                //   transition: '0.3s ease-out'
                // }
              }}
            >
              +27 73 186 1842
            </a>
            {/* <FooterLink to="/">Support</FooterLink> */}
            <a
              style={{
                textDecoration: "none",
                marginBottom: "0.5rem",
                fontSize: "14px",
                color: "#c79908",

                // &:hover {
                //   color: '#800000',
                //   transition: '0.3s ease-out'
                // }
              }}
            >
              jobillsapp@gmail.com
            </a>
            {/* <FooterLink to="/">Sponsorships</FooterLink> */}
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          {/* <FooterLinkItems>
            <FooterLinkTitle>Community</FooterLinkTitle>
            <FooterLink to="/about">Forum</FooterLink>
            <FooterLink to="/">Newsletter</FooterLink>
            <FooterLink to="/">Ambassadors</FooterLink>
            <FooterLink to="/">Code of Conduct</FooterLink>
          </FooterLinkItems> */}
          <FooterLinkItems>
            <FooterLinkTitle>Social Media</FooterLinkTitle>
            <a
              style={{
                textDecoration: "none",
                marginBottom: "0.5rem",
                fontSize: "14px",
                color: "#c79908",

                // &:hover {
                //   color: '#800000',
                //   transition: '0.3s ease-out'
                // }
              }}
              href="https://www.linkedin.com/in/jobills-b47506214/"
              target="_blank"
            >
              LinkedIn
            </a>
            <a
              style={{
                textDecoration: "none",
                marginBottom: "0.5rem",
                fontSize: "14px",
                color: "#c79908",

                // &:hover {
                //   color: '#800000',
                //   transition: '0.3s ease-out'
                // }
              }}
              href="https://www.reddit.com/user/Jobills"
              target="_blank"
            >
              Reddit
            </a>
            <a
              style={{
                textDecoration: "none",
                marginBottom: "0.5rem",
                fontSize: "14px",
                color: "#c79908",

                // &:hover {
                //   color: '#800000',
                //   transition: '0.3s ease-out'
                // }
              }}
              href="https://twitter.com/Jobills1"
              target="_blank"
            >
              Twitter
            </a>
            <a
              style={{
                textDecoration: "none",
                marginBottom: "0.5rem",
                fontSize: "14px",
                color: "#c79908",

                // &:hover {
                //   color: '#800000',
                //   transition: '0.3s ease-out'
                // }
              }}
              href="https://m.facebook.com/Jobills-103173278673937/#_=_"
              target="_blank"
            >
              Facebook
            </a>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterContainer>
      <FooterBottom>
        <p>
          © {new Date().getFullYear()} JOBILLS. All Rights Reserved - Developed
          by
          <a
            style={{
              textDecoration: "none",
              marginBottom: "0.5rem",
              fontSize: "14px",
              color: "#c79908",

              // &:hover {
              //   color: '#800000',
              //   transition: '0.3s ease-out'
              // }
            }}
            href="https://thamsanqaj-fea43.web.app/"
            target="_blank"
          >
            {" "}
            Thamsanqa J
          </a>
        </p>
        <p>Privacy · Terms · Sitemap · Company Details</p>
      </FooterBottom>
    </>
  )
}

export default Footer

const FooterContainer = styled.div`
  padding: 5rem calc((100vw - 1100px) / 2);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: #000;
  background: #fafafb;
`
const FooterDesc = styled.div`
  padding: 0 2rem;

  h1 {
    margin-bottom: 3rem;
    color: #c79908;
  }

  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`
const FooterLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`
const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem;

  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`
const FooterLinkTitle = styled.h2`
  font-size: 14px;
  margin-bottom: 16px;
`

const FooterBottom = styled.div`
  border-top: 1px solid lightgray;
  padding: 20px;
  // background-color: #f7f7f7;
  bottom: 0;
  text-align: center;

  p {
    padding: 5px;
    font-size: 14px;
  }
`
// const FooterContainer = styled.div``

import * as React from "react"
import { Link as LinkS } from "react-scroll"
import styled from "styled-components"
import { FaBars } from "react-icons/fa"
import { animateScroll as scroll } from "react-scroll"
import { Button } from "./Buttons"

const Header = ({ toggle }) => {
  const [scrollNav, setScrollNav] = React.useState(false)

  // Navigation
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop()
  }

  return (
    <Nav scrollNav={scrollNav}>
      <NavContainer>
        <NavLink
          onClick={toggleHome}
          style={{ color: "#C79908", fontSize: "25px", fontWeight: "700" }}
          to="/"
        >
          JOBILLS
        </NavLink>
        <MobileIcon onClick={toggle}>
          <FaBars />
        </MobileIcon>
        <NavMenu>
          <NavLink
            to="about"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            About Us
          </NavLink>
          <NavLink
            to="products"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Products
          </NavLink>
          <NavLink
            to="devs"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            For Developers
          </NavLink>
          <NavLink
            to="contact"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Contact Us
          </NavLink>
        </NavMenu>
        <NavBtn>
          <NavLink
            to="contact"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            <Button primary="true" round="true">
              Join Us
            </Button>
          </NavLink>
        </NavBtn>
      </NavContainer>
    </Nav>
  )
}

export default Header

const Nav = styled.nav`
background:${({ scrollNav }) =>
  scrollNav
    ? " linear-gradient(90deg, rgba(0,0,0,1) 35%, rgba(0,0,0,1) 100%);"
    : "transparent"}
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -80px;
  
  position: sticky;
  top: 0;
  z-index: 10;

  


  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
  
`
const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
`
const NavLink = styled(LinkS)`
  color: #c79908;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
`
const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #c79908;
  }
`
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -48px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
*{
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding:0;
    box-sizing: border-box; 
    

}
`
